<template>
  <div class="text-page container py-5">
    <BaseHeading>
      Podnety, pochvaly, sťažnosti
    </BaseHeading>
    <div class="content">
      <p>Svoje otázky, pochvaly či prípadné sťažnosti k finančnému sprostredkovaniu zo strany Generali Slovenská distribúcia, a. s. alebo podriadeného finančného agenta, s ktorým má uzavretú zmluvu, nám odošlite prostredníctvom nášho <router-link :to="{ path: '/', hash: '#kontakt' }">formulára</router-link>. </p>
      <h2>Informácie k vybavovaniu vašich sťažností</h2>
      <p>Sťažnosťou sa rozumie námietka zo strany klienta/potenciálneho klienta na činnosť/nečinnosť samostatného finančného agenta, alebo podriadeného finančného agenta, s ktorým má uzavretú zmluvu, ktorou sa klient/potenciálny klient domáha svojich práv.</p>
      <ul>
        <li>Sťažnosť môžete podať ktorýmkoľvek z uvedených spôsobov:
          <ul>
            <li>listom na adresu sídla samostatného finančného agenta - Generali Slovenská distribúcia, a. s., Lamačská cesta 3/A, Bratislava 841 04;</li>
            <li>osobne na pobočke; </li>
            <li>e-mailom na adresu <a href="mailto:generali.sk@generali.com">generali.sk@generali.com</a>; </li>
            <li>prostredníctvom <router-link :to="{ path: '/', hash: '#kontakt' }">webového formulára</router-link>;</li>
            <li>telefonicky na čísle <a href="tel:+421238111117">02/38111117</a>.</li>
          </ul>
        </li>
        <li>Nezabudnite uviesť svoje meno, adresu a čo najpresnejšie popísať, s čím vám môžeme pomôcť - t.j. akej veci sa sťažnosť týka, na aké nedostatky poukazujete a čoho sa domáhate.</li>
        <li>V prípade podávania sťažnosti písomnou formou ju musíte podpísať. </li>
        <li>V prípade, že o to požiadate, doručenie Vašej sťažnosti písomne potvrdíme.</li>
        <li>Ak Vaša sťažnosť neobsahuje požadované doklady či iné náležitosti nevyhnutné pre jej vybavenie, o tejto skutočnosti vás budeme informovať. Na naše  požiadanie ste povinný doložiť požadované doklady resp. náležitosti k podanej sťažnosti. Ak to v stanovenej lehote neurobíte, nebudeme môcť vybavenie sťažnosti ukončiť a sťažnosť odložíme.</li>
        <li>Vašu sťažnosť prešetríme najneskôr do 30 dní od jej doručenia. Lehota na vybavenie sťažnosti začne nasledujúci deň po jej doručení. Ak si vybavenie sťažnosti vyžiada dlhšiu dobu, budeme vás o predĺžení lehoty informovať. Lehotu na vybavenie sťažnosti môžeme predĺžiť najviac na 60 dní odo dňa jej doručenia.</li>
        <li>Do doby vybavenia sťažnosti nezapočítame dobu, v ktorej sme vás vyzvali na doloženie požadovaných dokladov.</li>
        <li>Sťažnosť považujeme za vybavenú, ak ste boli informovaný o výsledku prešetrenia sťažnosti.</li>
        <li>V prípade opakovanej sťažnosti v tej istej veci najskôr prekontrolujeme správnosť vybavenia predchádzajúcej sťažnosti. Ak bola predchádzajúca sťažnosť vybavená správne, túto skutočnosť vám oznámime s odôvodnením a poučením, že ďalšie opakované sťažnosti odložíme. Ak prekontrolovaním vybavenia predchádzajúcej sťažnosti zistíme, že nebola vybavená správne, opakovanú sťažnosť prešetríme a vybavíme.</li>
        <li>V prípade vašej nespokojnosti s vybavením sťažnosti máte možnosť obrátiť sa na Národnú banku Slovenska. </li>
        <li>Spory vyplývajúce zo sprostredkovania poistenia je možné riešiť aj prostredníctvom alternatívneho riešenia sporov podľa zákona č. 391/2015 Z. z. o alternatívnom riešení spotrebiteľských sporov. Po súhlase oboch strán je tiež možné spor riešiť mimosúdnym vyrovnaním na základe zákona č. 420/2004 Z. z. o mediácii v platnom znení.</li>
      </ul>
    </div>
  </div>
</template>

<script>
import BaseHeading from '@/components/BaseHeading.vue';

export default {
  components: {
    BaseHeading,
  },
};
</script>
